@import "/src/Theme/custom.scss";

.user-management-tab{
    width: 100%;
    .accordion{
        &.settings-usermanagement{
            background-color: $secondary-color;
            border-radius: 0.5rem;
            padding: 0 1rem;
            .card{
                border: none;
                border-bottom: 1px solid $light-gray;
                background-color: unset;
                border-radius: 0;
                .card-header{
                    border: none;
                    border-radius: 0;
                    padding: 0.5rem 0;
                    background-color: unset;
                    .form-check.form-switch.switch-lg{
                        width: 6.25rem;
                    }
                    .form-check.form-switch.switch-lg input ~ label{
                        font-size: 0.75rem;
                    }
                    .toggle-button-expanded,.toggle-icon-collapsed{
                        display: inline-block;
                        cursor: pointer;
                        svg{
                            transition: 0.3s ease all;
                        }
                    }
                    .toggle-icon-collapsed{
                        svg{
                            transform: rotate(90deg);
                        }
                    }
                }
                .accordion-collapse{
                    .card-body{
                        padding: 0.35rem 0.35rem 0.85rem !important;
                        background-color: unset !important;
                    }
                }
                .form-check.form-switch.switch-lg{
                    border: none;
                    box-shadow: inset 3px 2px 6px rgba(0, 0, 0, 0.09), inset -2px -1px 6px rgba(255, 255, 255, 0.82);;
                }
            }
        }
    }
}