/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";
@import "./Theme/custom.scss";

.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #F27174;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn-primary,.btn-primary-shadow {
    @include button-variant($primary-color, $primary-color, $white, lighten($primary-color, 5%), lighten($primary-color, 3%), $white);
}
.link-info {
    @include button-variant(transparent, transparent, $info-color, transparent, transparent, $primary-color);
}
.link-secondary {
  @include button-variant(transparent, transparent, $text-gray02, transparent, transparent, $info-color);
}
.btn-info {
    @include button-variant($info-color, $info-color, $white, darken($info-color, 5%), darken($info-color, 5%), $white);
}
.btn-link {
    @include button-variant(transparent, transparent, $primary-color, transparent, transparent, darken($primary-color, 5%));
}
.btn-secondary,.btn-secondary-shadow {
    @include button-variant($secondary-color, $secondary-color, $primary-color, $secondary-color, $secondary-color, lighten($primary-color, 5%));
}
.btn-secondary-gray{
    @include button-variant($secondary-color, $secondary-color, $text-gray, $secondary-color, $secondary-color, $text-gray02);
}
a{
  transition: 0.3s ease all;
  &:hover{
    color: $primary-color;
  }
}



// Scroll Bar
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  @media only screen and (max-width: $xs-screen){
      width: 4px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: $light-gray;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $info-color;
  border-radius: 6px;
  box-shadow: none;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primary-color;
}


// Gray Backgroud
.bg-offwhite{
  background-color: $light-border;
  &.border-top{border-color: $border-light !important;}
}



// Colors
.color-primary{ color: $primary-color;}
.color-secondary{ color: $secondary-color;}
.color-info{ color: $info-color;}
.color-dark01{ color: $dark01;}
.color-dark02{ color: $dark02;}
.color-gray{ color: $text-gray;}
.color-gray02{ color: $text-gray02;}
.color-gray03{ color: $text-gray03;}
.color-med-gray{color: $border-medium;}
.color-black{ color: $black;}
.color-white{ color: $white;}

.bg-body{background-color: $secondary-color;}
.bg-primary{background-color: $primary-color !important;}
.bg-secondary{background-color: $secondary-color !important;}
.bg-gray{background-color: $light-gray;}
.bg-med-gray{background-color: $border-medium;}
.bg-info{background-color: $info-light !important;}


// Font Weight
.font-light{font-weight: $font-light;}
.font-regular{font-weight: $font-regular;}
.font-medium{font-weight: $font-medium;}
.font-semibold{font-weight: $font-semibold;}
.font-bold{font-weight: $font-bold;}
.font-extrabold{font-weight: $font-extrabold;}


// Misc
.overflow-y-overlay{overflow-y: overlay !important;}
.overflow-y-auto{overflow-y: auto !important;}
.rounded-4{border-radius: 0.5rem;}

// Form
form{
  .form-control {
    &.form-secondary{
      background-color: $secondary-color;
      color: $text-gray03;
      font-weight: $font-medium;
      font-size: 14px;
      line-height: 22px;
      border: none;
      border-radius: 4px;
      outline: none;
      &:focus {
        box-shadow: none;
      }
      &::placeholder {
          color: $border-medium;
          font-weight: $font-medium;
          font-size: 14px;
          line-height: 22px;
      }
    }
    &.w-md{
      width: 8.125rem;
    }
  }
  .invalid-feedback{
    font-weight: $font-semibold;
    font-size: 0.813rem;
    color: $primary-color;
    margin-top: 0.5rem;
  }
  .valid-feedback{
    font-weight: $font-semibold;
    font-size: 0.813rem;
    color: $success-color;
    margin-top: 0.5rem;
  }
}

.form-control{
  &.neu-form,&.neu-form:focus{
    background-color: $secondary-color;
    background: linear-gradient(314.71deg, rgba(0, 0, 0, 2e-06) 8.64%, rgba(0, 0, 0, 0.02) 89.83%), #F0F0F0;
    border: 3px solid $border-light;
    border-radius: 1rem;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15), -2px -2px 4px rgba(255, 255, 255, 0.36), inset 3px 2px 6px 1.5px rgba(0, 0, 0, 0.09), inset -2px -1px 6px 1.5px rgba(255, 255, 255, 0.82);
    font-size: 0.938rem;
    line-height: 1.5rem;
    font-weight: $font-medium;
    color: $dark01;
    min-height: 3rem;
    &::placeholder{
      font-size: 0.938rem;
      line-height: 1.5rem;
      font-weight: $font-medium;
      color: $border-medium;
    }
  }
  &.form-md{
    min-height: 3.375rem;
    border-radius: 1.125rem;
    padding: 0.75rem 1.125rem;
  }
}

.input-group{
  &.form-group-labeled{
    position: relative;
    input.form-control{
      border-radius: 1rem !important;
      padding-right: 5rem !important;
      &.form-md{
        &~.input-group-text{
          top: 14px;
        }
      }
    }
    .input-group-text{
      background-color: $secondary-color;
      background: linear-gradient(314.71deg, rgba(0, 0, 0, 0.03) 8.64%, rgba(0, 0, 0, 3e-06) 89.83%), #F0F0F0;
      box-shadow: -3px -2px 12px rgba(0, 0, 0, 0.08), 4px 3px 9px rgba(255, 255, 255, 0.295318), inset 3px 2px 4px rgba(0, 0, 0, 0.11), inset -2px -1px 3px rgba(255, 255, 255, 0.926491);
      border-radius: 0.5rem !important;
      position: absolute;
      right: 10px;
      top: 11px;
      font-size: 0.813rem;
      line-height: 1.25rem;
      color: $primary-color;
      z-index: 5;
      border:0;
      cursor: default;
      span {
          font-weight: $font-semibold;
      }
      &.password-icon {
        position: absolute; 
        right: 0.5rem;
        top: 20% !important;
        box-shadow: unset;
        background: unset;
        cursor: pointer;
    }
  }
  }
}

textarea.form-control{
  &.neu-form{
    padding: 1rem;
  }
  &.form-md{
    min-height: 6.875rem;
  }
  &.is-invalid,&:invalid{
    background-position: unset;
  }
}


// Spacing
.gap-y-1{row-gap: 0.25rem;}
.gap-y-2{row-gap: 0.5rem;}
.gap-y-3{row-gap: 1rem;}
.gap-y-4{row-gap: 1.5rem;}
.gap-y-5{row-gap: 3rem;}

.gap-x-1{column-gap: 0.25rem;}
.gap-x-2{column-gap: 0.5rem;}
.gap-x-3{column-gap: 1rem;}
.gap-x-4{column-gap: 1.5rem;}
.gap-x-5{column-gap: 3rem;}

svg.min-10{min-width: 10px;}
.icon-44{
  width: 2.75rem;
  height: 2.75rem;
  min-width: 2.75rem;
}


.invalid-feedback{
  font-weight: $font-semibold;
  font-size: 0.813rem;
  color: $primary-color;
  margin-top: 0.5rem;
}
.valid-feedback{
  font-weight: $font-semibold;
  font-size: 0.813rem;
  color: $success-color;
  margin-top: 0.5rem;
}