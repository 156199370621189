@import "/src/Theme/custom.scss";

.accordion {
    width: 100%;
    .order-tab {
        .card {
            background-color: transparent;
            margin: 0 0 0.625rem;
            border: 1px solid $border-light;
            border-radius: 1rem;
            overflow: hidden;
            .card-header{
                border-radius: none;
                background-color: $white;
                border: none;
            }
        }
    }
}
