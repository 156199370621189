@import "/src/Theme/custom.scss";
.search {
    .search-container {
        width: auto;
        min-width: 150px;
        max-width: 250px;
        height: 50px;
        box-shadow: inset -3px -3px 4px $white, inset 1px 1px 4px rgba(97, 97, 97, 0.5);
        display: flex;
        border-radius: 10px;
        padding: 10px;
        background-color: $white;
        align-items: center;
        .input-group-text {
            background-color: transparent;
            border-left: 0;
            left: 0;
            z-index: 1;
            top: 0;
            bottom: 0;
            border: 0;
            svg {
                margin: auto;
            }
        }
        .form-control {
            border: none;
            color: $text-gray03;
            font-size: 18px;
            line-height: 22px;
            padding-right: 0;
            &:focus {
                box-shadow: unset;
                border: 0;
            }
            &::placeholder {
                color: $text-gray03;
                font-size: 18px;
                line-height: 22px;
            }
        }
    }
}
