@import "/src/Theme/custom.scss";

.dropdowns {
    width: auto;
    .button-dropdown {
        .dropdown {
            position: relative;
            button {
                padding: 0.5rem 1.5rem;
                font-size: 13px;
                line-height: 24px;
                border-radius: 0.5rem;
                box-shadow: -2px -2px 4px rgba(255, 255, 255, 0.95), 3px 3px 4px rgba(97, 97, 97, 0.12);
                border: 0;
                font-weight: 600;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                gap: 8px;
                &::after {
                    display: none;
                }
                &:active,
                &:focus {
                    border: 0;
                    outline: 0;
                    box-shadow: -2px -2px 4px rgba(255, 255, 255, 0.95), 3px 3px 4px rgba(97, 97, 97, 0.12);
                }
                svg {
                    * {
                        fill: $text-gray02;
                    }
                }
                &.secondary-gray {
                    background-color: $secondary-color;
                    color: $text-gray;
                }
                &.btn-info {
                    svg {
                        * {
                            fill: $white;
                        }
                    }
                    &:active {
                        color: $white;
                        background-color: $info-active;
                    }
                }
                &.btn-sm {
                    padding: 0.25rem 0.935rem;
                }
                &.link-secondary {
                    box-shadow: none;
                    padding: 0.25rem 1rem;
                    &:hover,
                    &:focus,
                    &:active,
                    &:focus:active {
                        svg {
                            * {
                                fill: $info-color;
                            }
                        }
                    }
                }
            }
            .show {
                inset: unset !important;
                top: 10px !important;
                right: 0 !important;
                background: $secondary-color;
                box-shadow: -2px -2px 4px rgba(255, 255, 255, 0.95), 3px 3px 4px rgba(97, 97, 97, 0.12);
                border-radius: 8px;
                border: 0;
                max-height: 11.5rem;
                overflow: hidden;
                overflow-y: auto;
                .dropdown-item {
                    color: $text-gray02;
                    font-weight: 500;
                    font-size: 14px;
                    &.active,
                    &:hover {
                        color: $primary-color;
                        background-color: unset;
                    }
                }
                .add-custom-unit {
                    position: sticky;
                    bottom: 0;
                    left: 0;
                    background-color: $secondary-color;
                    margin: 0;
                    padding: 0;
                    border-top: 1px solid $light-gray;
                }
                &.fill-primary {
                    .dropdown-item {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .button-spinner{
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            z-index: 0;
                        }
                        svg {
                            path {
                                fill: $primary-color;
                            }
                        }
                    }
                }
            }
            
            /* width */
            ::-webkit-scrollbar {
                width: 2px;
                height: 2px;
                @media only screen and (max-width: $xs-screen){
                    width: 2px;
                }
            }
            &.show {
                .btn.btn-info.dropdown-toggle {
                    color: $white;
                    background-color: $info-active;
                }
            }

            .form-control-sm {
                padding: 0.25rem;
                font-size: 0.813rem;
                box-shadow: none;
            }
        }
    }
}
