
@import "/src/Theme/custom.scss";

a{text-decoration: none; color: inherit;}
.Buttons {
    width: auto;
    .btn{
        font-weight: 600;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        svg{margin-right: 5px;}
        span{
            display: inline-block;
            margin-left: 3px;
            svg{margin-right: 0;}
        }
        &.px-0,&.px-1,&.px-2{
            svg{
                margin: 0;
            }
        }
        &.btn-sm{
            padding: 0.25rem 0.935rem;
        }
    }
    .btn-primary,.btn-primary-shadow,.btn-info{
        padding: 0.5rem 1.5rem;
        font-size: 13px;
        line-height: 24px;
        border-radius: 0.5rem;
        svg{
            *{
                fill: $white;
            }
        }
        &:disabled, &.disabled{
            color: $white;
        }
        &:focus,&:active,&:focus:active{
            box-shadow: none;
        }
        .count{
            display: block;
            min-width: 24px;
            height: 24px;
            border-radius: 30px;
            font-size: 13px;
            font-weight: $font-semibold;
            line-height: 24px;
            text-align: center;
            margin-right: 6px;
            padding: 0 3px;
            background-color: $white;
            color: $primary-color
        }
    }
    .btn-info{
        &:active{
            color: $white;
        }
    }
    .btn-primary-lg{
        padding: 1rem 5rem;
        font-size: 15px;
        line-height: 24px;
        border-radius: 0.625rem;
    }
    .btn-primary-md{
        padding: 0.688rem 3rem;
        font-size: 15px;
        line-height: 24px;
        border-radius: 0.625rem;
        &:disabled{
        background-color: $text-gray03;
        border-color: $text-gray03;
        cursor:not-allowed;
        }
        @media only screen and (max-width: $xs-screen) {
            padding: 0.688rem 0.75rem;
        }

    }
    .btn-secondary,.btn-secondary-shadow,.btn-secondary-gray{
        padding: 0.5rem 1.5rem;
        font-size: 13px;
        line-height: 24px;
        border-radius: 0.5rem;
        svg{
            *{
                fill: $primary-color;
            }
        }
        &:disabled, &.disabled{
            color: $info-color;
        }
        &:focus,&:active,&:focus:active{
            box-shadow: none;
            color: $info-color;
            svg{
                *{
                    fill: $info-color;
                }
            }
        }
    }
    .btn-primary-gray{
        color: $white;
        background-color: $text-gray03;
        border-color: $text-gray03;
        padding: 0.5rem 1.5rem;
        font-size: 13px;
        line-height: 24px;
        border-radius: 0.5rem;
    }
    .btn-secondary-gray{
        svg{
            *{
                fill: $text-gray;
            }
        }
        &:focus,&:active,&:focus:active{
            color: $text-gray02;
            svg{
                *{
                    fill: $text-gray02;
                }
            }
        }
    }
    .link-info,.link-secondary{
        text-decoration: none;
        padding: 0;
        font-size: 14px;
        line-height: 24px;
    }
    .link-info{
        svg{
            *{
                fill: $info-color;
            }
        }
        &:hover,&:focus,&:active,&:focus:active{
            color: $primary-color;
            svg{
                *{
                    fill: $primary-color;
                }
            }
        }
    }
    .link-secondary{
        svg{
            *{
                fill: $text-gray02;
            }
        }
        &:hover,&:focus,&:active,&:focus:active{
            color: $info-color;
            svg{
                *{
                    fill: $info-color;
                }
            }
        }
    }
    .btn-link{
        text-decoration: none;
        padding: 0 .5rem;
        font-size: 14px;
        line-height: 24px;
        svg{
            *{
                fill: $primary-color;
            }
        }
        &:focus,&:hover,&:focus:hover{
            text-decoration: underline;
            box-shadow: none;
            svg{
                *{
                    fill: $primary-color;
                }
            }
        }
        &:disabled,&.disabled{
            color: $text-gray02;
            opacity: 0.3;
        }
    }
    .btn-primary-shadow,.btn-secondary-shadow, .btn-secondary-gray{
        box-shadow: -2px -2px 4px rgba(255, 255, 255, 0.95), 3px 3px 4px rgba(97, 97, 97, 0.12);
        &:focus,&:active,&:focus:active{
            box-shadow: -2px -2px 4px rgba(255, 255, 255, 0.95), 3px 3px 4px rgba(97, 97, 97, 0.12);
        }
        &:disabled,&.disabled{
            opacity: 0.5;
        }
    }
    .btn-white-rounded, .btn-primary-rounded{
        font-size: 24px;
        border-radius: 50px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.1)) drop-shadow(-1px -1px 1px rgba(255, 255, 255, 0.5));
        box-shadow: none;
        padding: 0.75rem;
        position: relative;
        svg{
            margin: 0;
        }
        &:focus,&:active,&:focus:active{
            box-shadow: none;
            filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(-1px -1px 1px rgba(255, 255, 255, 0.5));
            svg{
                margin: 0;
            }
        }
        .count{
            display: block;
            min-width: 20px;
            height: 20px;
            border-radius: 30px;
            box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
            position: absolute;
            top: -3px;
            right: -5px;
            font-size: 10px;
            font-weight: $font-medium;
            line-height: 20px;
            text-align: center;
            margin: 0;
            padding: 0 3px;
        }
        @media only screen and (max-width: $xl-screen) {
            padding: 0.625rem;
        }
        @media only screen and (max-width: $xs-screen) {
            padding: 0.5rem;
            .count{
                right: -6px;
                top: -4px;
            }
        }
    }

    .btn-white-rounded{
        background-color: $white;
        color: $primary-color;
        svg{
            *{
                fill: $primary-color;
            }
        }
        &:focus,&:active,&:focus:active{
            color: $primary-color;
            svg{
                *{
                    fill: $primary-color;
                }
            }
        }
        .count{
            background-color: $primary-color;
            color: $white;
        }
    }

    .btn-primary-rounded{
        background-color: $primary-color;
        color: $white;
        svg{
            *{
                fill: $white;
            }
        }
        &:focus,&:active,&:focus:active{
            color: $primary-color;
            svg{
                *{
                    fill: $white;
                }
            }
        }
        .count{
            background-color: $white;
            color: $primary-color;
        }
    }

    .btn-button-positive{
        border-radius: 8px;
        background-color: $info-color;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2), -4px -4px 10px rgba(255, 255, 255, 0.4), 8px 8px 11px rgba(0, 0, 0, 0.22);
        border: 1px solid rgba($color: $white, $alpha: 0.09);
        width: 36px;
        height: 36px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        svg{
            width: 20px;
            height: 20px;
            min-width: 20px;
            fill: rgba($color: $black, $alpha: 0.23);
            margin: 0;
            *{
                fill: rgba($color: $black, $alpha: 0.23);
            }
        }
    }
    .btn-button-wishlist,.btn-button-back{
        border-radius: 8px;
        background-color: $secondary-color;
        border: 1px solid rgba($color: $white, $alpha: 0.09);
        width: 36px;
        height: 36px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        box-shadow: -3px -3px 6px rgba(255, 255, 255, 0.2), 5px 5px 15px rgba(0, 0, 0, 0.2), inset 1px 1px 1px rgba(255, 255, 255, 0.24);
        &:focus,&:active,&:focus:active{
            box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.2), 3px 3px 10px rgba(0, 0, 0, 0.2), inset 1px 1px 1px rgba(255, 255, 255, 0.24);
        }
        svg{
            width: 20px;
            height: 20px;
            min-width: 20px;
            margin: 0;
        }
    }
}
