// @use "/src/Theme/custom.scss" as theme;
@import "/src/Theme/custom.scss";

.tabs {
    width: 100%;
    .tab-button-type {
        .nav-pills {
            border-bottom: 0;
            .nav-item {
                margin-right: 0.5rem;
                cursor: pointer;
                .nav-link {
                    color: $text-gray;
                    font-size: 1rem;
                    line-height: 1.25rem;
                    font-weight: $font-semibold;
                    border-radius: 8px;
                    border: none;
                    outline: none;
                    padding: 0.875rem 0.75rem;
                    display: flex;
                    align-items: center;
                    @media only screen and (max-width: $xl-screen){font-size: 0.85rem;}
                    &:hover {
                        color: $text-gray;
                    }
                    .badge{
                        border-radius: 20px;
                        font-size: 0.625rem;
                        line-height: 1.125rem;
                        min-width: 1.125rem;
                        min-height: 1.125rem;
                        padding: 0 0.35rem;
                        margin-left: 0.375rem;
                    }
                }
                .active {
                    background-color: $secondary-color;
                    box-shadow: -2px -2px 4px rgba(255, 255, 255, 0.95), 3px 3px 4px rgba(97, 97, 97, 0.12);
                    color: $primary-color;
                    &:hover {
                        color: $primary-color;
                    }
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        .tab-content {
            .product-details {
                background-color: $white;
                border-radius: 1rem;
                border: 1px solid $border-light;
                padding: 2rem;
            }
        }
    }
    .tab-with-border {
        .nav-tabs {
            border-bottom: 0;
            .nav-item {
                margin-right: 2.5rem;
                .nav-link {
                    color: $text-gray;
                    font-size: 1rem;
                    line-height: 1.25rem;
                    font-weight: $font-semibold;
                    border-radius: 0px;
                    border: none;
                    outline: none;
                    background: none;
                    padding: 0.5rem 0;
                    &:hover {
                        color: $info-color;
                    }
                }
                .active {
                    color: $primary-color;
                    background: none;
                    box-shadow: 0px 3px 0px $primary-color;
                    border: 0;
                    &:hover {
                        color: $primary-color;
                    }
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    .tab-with-button-vertical {
        
        .nav-tabs {
            display: flex;
            flex-direction: column;
            .nav-link {
                color: $text-gray;
                font-weight: $font-medium;
                font-size: 13px;
                line-height: 1rem;
                margin: 2px 0;
                border-radius: 0.5rem;
                border: 0;
                box-shadow: none;
                width: 100%;
                text-align: left;
                &:hover,&:focus{
                    border: none;
                    box-shadow: none;
                    color: $info-color;
                }
                &.active {
                    color: $primary-color;
                    background-color: transparent;
                    border: 0;
                    box-shadow: -2px -2px 4px rgba(255, 255, 255, 0.95), 3px 3px 4px rgba(97, 97, 97, 0.12);
                    &:focus,&:active,&:focus:active{
                        box-shadow: -2px -2px 4px rgba(255, 255, 255, 0.95), 3px 3px 4px rgba(97, 97, 97, 0.12);
                    }
                }
            }
        }
        .tab-content {
            flex: 0 0 auto;
            width: 50%;
        }
    }
}


.flex-fill-row{
    height: 50%;
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-flow: column;
}
.flex-fill-column{
    height: 100%;
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-flow: column;
}