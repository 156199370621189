@import "/src/Theme/custom.scss";

.drawer {
    width: auto;
    
}
.small-screen {
    height: fit-content;
    margin: auto;
    background-color: $secondary-color;
    border: none;
    box-shadow: -1px -1px 4px rgba(255, 255, 255, 0.95), 3px 3px 4px rgba(97, 97, 97, 0.12);
    border-radius: 24px;
}
.product-filter{
    margin: 0 1.875rem 1.875rem 0;
    background-color: $secondary-color;
    border: none;
    box-shadow: -1px -1px 4px rgba(255, 255, 255, 0.95), 3px 3px 4px rgba(97, 97, 97, 0.12);
    border-radius: 24px;
    top: unset;
    right: 0;
    bottom: 0;
    @media only screen and (max-width: $xs-screen) {
        margin: 10px;
        max-width: calc(100% - 20px);
        bottom: 60px;
    }
}