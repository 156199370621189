@import "/src/Theme/custom.scss";
.stepper {
    width: 100%;

    .custom-stepper {
      padding: 15px;
        .completed {
            background-image: url("../../../public/images/tick.svg");
            background-repeat: no-repeat;
            color: transparent;
            background-position: center;
            background-size: 18px;
            span {
                display: none;
            }
        }
     
     
    }

    ._53Ji7 {
        ._3uApM {
            display: none;
        }
    }
}
