@import "/src/Theme/custom.scss";
.range {
    width: 100%;
    margin: auto 0 0;

    .two-point-range {

        .range-slider__tooltip__label {
            background-color: transparent !important;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1rem;
            top: .7rem;
        }

        .range-slider__tooltip__caret::before{
            border-top-color: #FFF !important;
            top: .5rem !important;
        }

        .tooltip{
            opacity: 1;
            color: #fff;
            font-size: 12px;
            font-weight: 700;
            line-height: 15px;
        }
        

        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 20px 15px;
        background-color: rgba(33, 33, 33, 0.2);
        border-radius: 8px;
        .range-slider {
            display: flex;
            width: 100%;
            align-items: center;
            &>svg{
                min-width: 36px;
                z-index: 1;
                &:first-child{
                    transform: translateX(5px);
                }
                &:last-child{
                    transform: translateX(-5px);
                }
            }
            .track {
                height: 6px;
                width: 100%;
                box-shadow: inset -1px -1px 2px rgba(255, 255, 255, 0.4), inset 1px 1px 4px rgba(97, 97, 97, 0.5);
                border-radius: 4px;
                .thumb {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    top: -20px;
                    gap: 1px;
                    .thumb-text {
                        font-weight: $font-bold;
                        font-size: 12px;
                        line-height: 15px;
                        color: $white;
                    }
                    svg{
                        width: 12px;
                        min-width: 12px;
                        height: 6px;
                    }
                    &:focus {
                        outline: none;
                    }
                }
            }
        }
        .range-description {
            color: $white;
            width: 95%;
            text-align: center;
            span {
                font-weight: $font-medium;
                font-size: 12px;
                line-height: 15px;
                display: inline-block;
                margin-top: 5px;
            }
        }
    }

    .three-point-range {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;

        .range-slider__wrap{
            position: absolute;
            width: 100%;
        }


        .range-slider__tooltip__label {
            background-color: transparent !important;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1rem;
            top: -1rem;
        }

        .range-slider__tooltip__caret::before{
            border-top-color: #adabaf !important;
            top: -1rem !important;
        }
        
        .tooltip{
            opacity: 1;
            -webkit-text-fill-color: transparent;
            background: #3fa6e0;
            background: linear-gradient(122.81deg,#b9b8bb 16.48%,#625f68 83.42%);
            -webkit-background-clip: text;
            color: linear-gradient(122.81deg,#b9b8bb 16.48%,#625f68 83.42%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#B9B8BB",endColorstr="#625F68",GradientType=1);
            font-size: 12px;
            font-weight: 700;
            line-height: 15px;
        }
        

        .range-slider {
            display: flex;
            width: 100%;
            align-items: center;
            position: relative;
            justify-content: space-between;
            &>svg{
                width: 46px;
                height: 46px;
                min-width: 46px;
                z-index: 1;
                &:first-child{
                    transform: translateX(-10px);
                }
                &:last-child{
                    transform: translateX(10px);
                }
            }
            .icon-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            .gold-icon {
                // position: absolute;
                // left: 50%;
                // transform: translateX(-50%);
                z-index: 1;
            }
            .track {
                height: 10px;
                width: 100%;
                box-shadow: inset -1px -1px 2px rgba(255, 255, 255, 0.4), inset 1px 1px 4px rgba(97, 97, 97, 0.5);
                border-radius: 4px;
                .thumb-silver {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    top: -25px;
                    gap: 3px;
                    .thumb-text {
                        font-weight: $font-bold;
                        font-size: 12px;
                        line-height: 15px;
                        color:linear-gradient(122.81deg, #EFEFEF 16.48%, #C8C8C8 83.42%);
                        background: rgba(200, 200, 200, 1);
                        background: -moz-linear-gradient(122.81deg, #EFEFEF 16.48%, #C8C8C8 83.42%);
                        background: -webkit-linear-gradient(122.81deg, #EFEFEF 16.48%, #C8C8C8 83.42%);
                        background: linear-gradient(122.81deg, #EFEFEF 16.48%, #C8C8C8 83.42%);
                        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fae8ae",endColorstr="#ffbe17",GradientType=1);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                    svg{
                        width: 15px;
                        min-width: 15px;
                        height: 7px;
                    }
                    &:focus {
                        outline: none;
                    }
                }
                .thumb-gold {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    top: -25px;
                    gap: 3px;
                    .thumb-text {
                        font-weight: $font-bold;
                        font-size: 12px;
                        line-height: 15px;
                        color:#FEE283;
                        background: rgb(250,232,174);
                        background: -moz-linear-gradient(135deg, rgba(250,232,174,1) 0%, rgba(255,190,23,1) 100%);
                        background: -webkit-linear-gradient(135deg, rgba(250,232,174,1) 0%, rgba(255,190,23,1) 100%);
                        background: linear-gradient(135deg, rgba(250,232,174,1) 0%, rgba(255,190,23,1) 100%);
                        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fae8ae",endColorstr="#ffbe17",GradientType=1);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                    svg{
                        width: 15px;
                        min-width: 15px;
                        height: 7px;
                    }
                    &:focus {
                        outline: none;
                    }
                }
                .thumb-platinum {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    top: -25px;
                    gap: 3px;
                    .thumb-text {
                        font-weight: $font-bold;
                        font-size: 12px;
                        line-height: 15px;
                        color:linear-gradient(122.81deg, #B9B8BB 16.48%, #625F68 83.42%);
                        background: rgba(63, 166, 224, 1);
                        background: -moz-linear-gradient(122.81deg, #B9B8BB 16.48%, #625F68 83.42%);
                        background: -webkit-linear-gradient(122.81deg, #B9B8BB 16.48%, #625F68 83.42%);
                        background: linear-gradient(122.81deg, #B9B8BB 16.48%, #625F68 83.42%);
                        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#B9B8BB",endColorstr="#625F68",GradientType=1);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                    svg{
                        width: 15px;
                        min-width: 15px;
                        height: 7px;
                    }
                    &:focus {
                        outline: none;
                    }
                }
            }
        }
        .range-description {
            display: flex;
            justify-content: space-between;
            width: 100%;
            text-align: center;
            margin-top: 10px;
            .description-element {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 70px;
                text-align: center;
                @mixin bold-font($font-weight, $font-size, $line-height) {
                    font-weight: $font-weight;
                    font-size: $font-size;
                    line-height: $line-height
                }
                .icon-title {
                    @include bold-font($font-semibold, 14px, 17px);
                    color: $white;
                }
                .icon-value {
                    color: #99D6F2;
                     @include bold-font($font-semibold, 14px, 17px);
                }
                &.silver{
                    .icon-value {
                        color:#CACACA;
                    }
                }
                &.gold{
                    .icon-value {
                        color:#FEE283;
                    }
                }
                &.platinum{
                    .icon-value {
                        color:#cacaca;
                    }
                }
            }
        }
    }
}

.range-slider__wrap{
    input[type="range"]::after {
        content: "";
        position: absolute;
        cursor: pointer; position: absolute; 
        // background: linear-gradient(to right, rgb(49, 45, 56) 0%, rgb(49, 45, 56) 73.9936%, rgba(0, 0, 0, 0.1) 73.9936%, rgba(0, 0, 0, 0.1) 100%);
        box-shadow: inset -1px -1px 2px hsla(0,0%,100%,.4), inset 1px 1px 4px rgba(97,97,97,.5);
        height: 10px;
        width: var(--percentage);
        border-radius: 0.5rem;
    }
}

input[type="range"]::-webkit-slider-thumb {
    background: transparent !important;
}
input[type="range"]::-moz-range-thumb {
    background: transparent !important;
}
input[type="range"]::-ms-thumb {
    background: transparent !important;
}


.platinum {
    .range-slider__wrap{
        input[type="range"]::after {
            background-color: #312D38;
        }
    }
}
.gold {
    .range-slider__wrap{
        input[type="range"]::after {
            background-color: #FAE8AE;
        }
    }
}
.silver {
    .range-slider__wrap{
        input[type="range"]::after {
            background-color: #7D7C7C;
        }
    }
}
.diamond {
    .range-slider__wrap{
        input[type="range"]::after {
            background-color: #317093;
        }
    }
}

