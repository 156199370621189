@import "/src/Theme/custom.scss";

.carousel {
    width: auto;
    .dots-container {
        top: 5px;
        align-items: center;
        .dots {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            border: 1px solid $border-medium;
            cursor: pointer;
            z-index: 5;
            &.active {
                width: 10px;
                height: 10px;
                background-color: $text-gray;
                border-color: $text-gray;
            }
        }
    }
    .slick-slider {
        padding: 0px 0px 0px 20px;
        margin-bottom: 1.25rem;
        .slick-list {
            .slick-slide {
                .active-images {
                    width: 376px;
                    height: auto;
                    max-width: 100%;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }
            }
        }
        .slick-dots {
            justify-content: center;
            align-items: center;
            display: flex !important;
            overflow: auto;
            max-width: 100%;
            gap: 0.5rem;
            bottom: unset;
            position: relative;
            margin-top: 10px;
            // flex-wrap: wrap;
            padding: 4px 2px;
            li {
                display: inline-flex;
                width: 31px;
                min-width: 31px;
                height: 31px;
                border: 0;
                margin: 0;
                .image-options {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    border-radius: 6px;
                    .avatars {
                        width: 100%;
                        height: 100%;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        opacity: 0.6;
                        border-radius: 6px;

                    }
                }
                &.slick-active {
                    width: 38px;
                    height: 38px;
                    min-width: 38px;
                    filter: drop-shadow(-1px -1px 2px rgba(255, 255, 255, 0.95))
                        drop-shadow(1px 1px 2px rgba(97, 97, 97, 0.12));
                    .image-options {
                        img {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
.offer-promotion-carousal {
    height: 13.75rem;
    .slick-slider{
        height: 100%;
        padding: 0;
        button{
            &.slick-arrow{display: none !important;}
        }
        .slick-list{
            height: 100%;
            border-radius: 0.5rem;
            .slick-track{
                display: flex;
                gap: 0.938rem;
                height: 100%;
                *{max-height: 100%;}
                .slick-slide{
                    height: 100%;
                    border-radius: 0.5rem;
                    overflow: hidden;
                    min-width: 11.25rem;
                    &:nth-child(3n){
                        min-width: 20rem;
                    }
                    &>div{
                        height: 100%;
                        width: 100%;

                    }
                    .active-images{
                        height: 100%;
                        width: 100%;
                        .avatars{
                            height: 100%;
                            width: 100%;
                            .dash-newsoffer-image{
                                width: 100%;
                                height: 100%;
                                img{
                                    height: 100%;
                                    width: 100%;
                                    object-fit: cover;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.story-carousal{
    position: relative;
    .slick-slider{
        display: flex;
        align-items: center;
        position: relative;
        padding: 2.5rem 2.5rem 0;
        height: 100%;
        .slick-list{
            .slick-track{
                display: flex;
                align-items: center;
            }
        }
        button{
            &.slick-arrow{
                width: 2rem;
                height: 2rem;
                border-radius: 0.5rem;
                background-color: $secondary-color;
                z-index: 1;
                transition: 0.3s ease all;
                opacity: 1;
                &.slick-disabled{
                    opacity: 0;
                    pointer-events: none;
                }
                &::before{
                    width: 1.25rem;
                    height: 1.25rem;
                    display: block;
                    content: '';
                    opacity: 1;
                    margin: 0 auto;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 1rem;
                }
            }
            &.slick-prev{
                left: 0;
                &::before{
                    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.70711 8.70711C3.31658 8.31658 3.31658 7.68342 3.70711 7.29289L9.2929 1.7071C9.92286 1.07714 11 1.52331 11 2.41421V13.5858C11 14.4767 9.92286 14.9229 9.29289 14.2929L3.70711 8.70711Z' fill='url(%23paint0_linear_2824_31351)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_2824_31351' x1='4.66602' y1='6' x2='12.166' y2='8' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23B4B4B4'/%3E%3Cstop offset='1' stop-color='%23DEDEDE'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
                }
            }
            &.slick-next{
                right: 0;
                &::before{
                    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.2929 8.70711C11.6834 8.31658 11.6834 7.68342 11.2929 7.29289L5.7071 1.7071C5.07714 1.07714 4 1.52331 4 2.41421V13.5858C4 14.4767 5.07714 14.9229 5.70711 14.2929L11.2929 8.70711Z' fill='url(%23paint0_linear_2824_31351)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_2824_31351' x1='3' y1='5' x2='12' y2='9.5' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23B4B4B4'/%3E%3Cstop offset='1' stop-color='%23DEDEDE'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
                }
            }
        }
        .slick-list{
            width: 100%;
        }
    }
    .slick-dots{
        top: 0;
        bottom: unset;
        left: 0;
        display: flex !important;
        justify-content: center;
        gap: 0.75rem;
        li{
            margin: 0;
            width: 4rem;
            height: 0.5rem;
            button{
                width: 100%;
                height: 100%;
                border-radius: 0.5rem;
                background-color: rgba($color: $white, $alpha: 0.31);
                padding: 0;
                position: relative;
                overflow: hidden;
                &::before{
                    display: none;
                }
                &::after{
                    content: "";
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: $primary-color;
                    border-radius: 0.5rem;
                }
            }
            &.slick-active{
                button{
                    &::after{
                        width: 0;
                        animation: storyDotPlay 3000ms linear forwards;
                    }
                }
                &~li{
                    button{
                        &::after{
                            width: 0;
                        }
                    }
                }
            }
        }
    }
}

// News & Offers
.news-and-offers-large{
    .slick-slider{
        button{
            &.slick-arrow{
                display: none !important;
            }
        }
        .slick-list{
            overflow: hidden;
            border-radius: 8px;
            .slick-track{
                display: flex;
                gap: 15px;
            }
        }
        .offer-image{
            width: 100%;
            height: 13.75rem;
            overflow: hidden;
            border-radius: 8px;
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }
}

.news-and-offers-medium{
    .slick-slider{
        button{
            &.slick-arrow{
                display: none !important;
            }
        }
        .slick-list{
            .slick-track{
                display: flex;
                gap: 6px;
            }
        }
        .offer-image{
            width: 100%;
            height: 11.438rem;
            overflow: hidden;
            border-radius: 8px;
            border: 1px solid $light-border;
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }
}

@keyframes storyDotPlay {
    0%{width: 0%;}
    100%{width: 100%;}
}