@import "/src/Theme/custom.scss";

.custom-modal {
    .modal-dialog{
        width: 100%;
        margin: 0 auto;
        padding: 0.5rem;
    }
    .modal-content {
        position: relative;
        border-radius: 16px;
        background-color: $secondary-color;
        border: none;
        .modal-title {
            position: absolute;
            right: 12px;
            top: -46px;
            cursor: pointer;
        }
        .modal-body {
            padding: 0;
        }
    }
}
.story-modal{
    background-color: rgba($color: $dark02, $alpha: 0.7);
    .modal-dialog{
        min-height: 100vh;
        padding: 0;
        .modal-content {
            background-color: transparent;
            height: 100vh;
            padding: 3rem 0.5rem;
            .modal-title{display: none;}
            .modal-body{
                height: 100%;
                display: flex;
                flex-direction: column;
                &>div{
                    height: 100%;
                }
                .story-carousal{
                    height: 100%;
                }
            }
        }
    }
}
