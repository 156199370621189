@use "../../../src/Theme/custom.scss" as theme;

.authentication{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: theme.$secondary-color;
    min-height: 100vh;
    width: 100vw;
    padding: 2rem 1rem;
    .auth-certificates{
        max-width: 15rem;
        width: 90%;
    }
}
.auth-bg{
    background-image: url(../../../public/images/auth-bg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}