// @use "/src/Theme/custom.scss" as theme;
@import "/src/Theme/custom.scss";

.dialogue-children {
    padding: 45px 10px;
    display: flex;
    // width: 500px;
    margin: auto;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-color: $secondary-color;
    flex-direction: column;
    border-radius: 16px;
    max-width: 95vw;
    .dialogue-title {
        text-align: center;
        .typography {
            p {
                margin: auto;
                &.text-18{
                    span{
                        display: inline-block;
                    }
                }
            }
        }
    }
    .dialogue-buttons{
        display: flex;
        justify-content: center;
        margin-top: 35px;
        gap: 1rem;
        flex-wrap: wrap;
    }


}
