
@import "/src/Theme/custom.scss";

.dashboard {
    display: flex;
    width: 100vw;
    height: 100vh;
    padding: 1.875rem;
    gap: 1.875rem;
    overflow-x: hidden;
    .main {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        height: 100%;
        max-width: calc(100% - $drawer-width - 1.875rem);
        transition: all 0.3s ease;
        @media only screen and (max-width: $md-screen) {
            gap: 1rem;
        }
    }
    .sidebar.collapsed{
        &~.main{
            max-width: calc(100% - $drawer-collapsed-width - 1.875rem);
            @media only screen and (max-width: $md-screen) {
                max-width: calc(100% - $drawer-collapsed-width - 1.5rem);
            }
            @media only screen and (max-width: $xs-screen) {
                max-width: 100%;
            }
        }
    }
    @media only screen and (max-width: $md-screen) {
        padding: 1.5rem;
        gap: 1.5rem;
        .main{
            max-width: calc(100% - $drawer-md-width - 1.5rem);
        }
    }
    @media only screen and (max-width: $xs-screen) {
        padding: 1rem;
        gap: 1rem;
        padding-bottom: 65px;
        .main{
            max-width: 100%;
        }
    }
}
