@import "/src/Theme/custom.scss";

.settings{
    width: 100%;
    height: 40%;
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-flow: column;
    @media only screen and (max-width: $sm-screen) {
        overflow: hidden;
        overflow-y: unset;
    }
    .settings-body{
        height: 50%;
        flex-grow: 1;
        flex-shrink: 0;
        display: flex;
        flex-flow: column;
        @media only screen and (max-width: $sm-screen) {height: auto;}
        .tabs{
            height: 100%;
            .tab-with-border{
                height: 100%;
                flex-grow: 1;
                flex-shrink: 0;
                display: flex;
                flex-flow: column;
                .tab-content{
                    height: 100%;
                    overflow: hidden;
                    overflow-y: auto;
                    background-color: $white;
                    border-radius: 1rem;
                    .tab-pane{
                        padding: 2rem;
                        input.form-control.neu-form{
                            min-height: 2.625rem;
                            border-radius: 0.75rem;
                            color: $text-gray;
                        }
                    }
                }
            }
        }
    }
}