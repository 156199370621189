@import "/src/Theme/custom.scss";

.appBar {
    width: 100%;
    flex-grow: 0;
    .search{
        .search-container{
            @media only screen and (max-width: $md-screen) {
                max-width: 200px;
            }
            @media only screen and (max-width: $sm-screen) {
                max-width: 160px;
            }
        }
        @media only screen and (max-width: $xs-screen) {
            display: none;
        }
    }
}
