@import "/src/Theme/custom.scss";

.typography {
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    margin: 0;
    padding: 0;
    .font-secondary {
        font-family: "Oswald", sans-serif;
        text-transform: uppercase;
        font-weight: 500;
    }

    .logo-title {
        color: $white;
        font-weight: 600;
        font-size: 16px;
        line-height: 14px;
    }
}
.text-copyright .btn {
    font-size: 0.688rem;
    line-height: 1.375rem;
    padding: 0 0.25rem;
}

// Font Sizes
.text-11 {
    font-size: 0.688rem;
    line-height: 1.375rem;
    &.lh-1{line-height: 1rem;}
}
.text-12 {
    font-size: 0.75rem;
    line-height: 1.375rem;
}
.text-13 {
    font-size: 0.813rem;
    line-height: 1.375rem;
}
.text-14 {
    font-size: 0.875rem;
    line-height: 1.375rem;
}
.text-15 {
    font-size: 0.938rem;
    line-height: 1.375rem;
}
.text-16 {
    font-size: 1rem;
    line-height: 1.5rem;
}
.text-17 {
    font-size: 1.063rem;
    line-height: 1.5rem;
}
.text-18 {
    font-size: 1.125rem;
    line-height: 1.5rem;
    .text-20 {
        line-height: 2rem;
    }
}
.text-19 {
    font-size: 1.188rem;
    line-height: 1.5rem;
}
.text-20 {
    font-size: 1.25rem;
    line-height: 1.5rem;
}
.text-22 {
    font-size: 1.375rem;
    line-height: 1.5rem;
}
.text-24 {
    font-size: 1.5rem;
    line-height: 1.75rem;
}
.text-25 {
    font-size: 1.563rem;
    line-height: 1.75rem;
}
.text-26 {
    font-size: 1.625rem;
    line-height: 1.75rem;
}
.text-28 {
    font-size: 1.75rem;
    line-height: 2.25rem;
}
.text-30 {
    font-size: 1.875rem;
    line-height: 2.25rem;
}
.text-32 {
    font-size: 2rem;
    line-height: 2.25rem;
}
.text-34 {
    font-size: 2.125rem;
    line-height: 2.75rem;
}
.text-35 {
    font-size: 2.188rem;
    line-height: 2.75rem;
}
.text-36 {
    font-size: 2.25rem;
    line-height: 2.75rem;
}
.text-40 {
    font-size: 2.5rem;
    line-height: 2.75rem;
}
.text-44 {
    font-size: 2.75rem;
    line-height: 3.125rem;
}
.text-48 {
    font-size: 3rem;
    line-height: 3.125rem;
}
