@import "/src/Theme/custom.scss";
.custom-radio {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    margin: 5px 0;
    .form-check{
        display: inline-flex;
        align-items: center;
        gap: 0.75rem;
        margin-bottom: 0;
        .form-check-label{
            color: $text-gray02;
            font-size: 14px;
            font-weight: $font-medium;
            cursor: pointer;
            margin: 0;
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
            line-height: 16px;
        }
        input[type="radio"]{
            &:checked{
                &~.form-check-label{
                    color: $primary-color;
                }
            }
        }
    }
    input[type="radio"]{
        width: 24px;
        height: 24px;
        min-width: 24px;
        border: 1px solid $text-gray;
        background-color: transparent;
        box-shadow: none;
        outline: none;
        cursor: pointer;
        margin-top: 0;
        &:checked{
            background-color: $primary-color;
            border-color: $primary-color;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iNSIgY3k9IjUiIHI9IjUiIGZpbGw9IiNGMUYxRjEiLz4KPC9zdmc+Cg==");
            background-size: 10px;
            background-position: center;
            background-repeat: no-repeat;
            box-shadow: -2px -2px 4px rgba(255, 255, 255, 0.95), 3px 3px 4px rgba(97, 97, 97, 0.12);
        }
    }
}
.dropdown-item{
    .custom-radio{
        margin: 0;
    }
}