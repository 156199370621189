// Fonts
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&family=Oswald:wght@500&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f1f1 !important;
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
:root{
  font-size: 16px;
  line-height: 24px;
  scrollbar-color: rgb(236,43,47) rgb(215,215,215) !important;
  scrollbar-width: thin !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
