@import "/src/Theme/custom.scss";

.sidebar {
    transition: all 0.3s ease;
    width: $drawer-width;
    min-width: $drawer-width;
    background-color: $primary-color;
    border-radius: 1.25rem;
    margin: 0;
    display: flex;
    flex-direction: column;
    padding: 30px 20px 20px;
    @media only screen and (max-width: $md-screen) {
        width: $drawer-md-width;
        min-width: $drawer-md-width;
    }
    .logo-container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: relative;
        margin-bottom: 50px;
        @media only screen and (max-width: $md-screen) {
            margin-bottom: 20px;
        }
        .logo-image {
            margin: auto;
            text-align: center;
            cursor: pointer;
            .avatars {
                margin: auto;
            }
            .typography {
                p {
                    margin: auto;
                }
            }
        }
        svg {
            cursor: pointer;
            position: absolute;
            right: 0;
            &:hover {
                rect {
                    fill: $white;
                }
                path {
                    fill: $primary-color;
                }
            }
        }
    }
    .list-group {
        // margin-top: 20px !important;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        margin: 10px 0;
        padding: 0;
        overflow: hidden;
        overflow-y: auto;
        margin-right: -4px;
        padding-right: 4px;
        .list-group-item {
            background-color: transparent;
            border: 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 0;
            margin-bottom: 1rem;
            padding: 0.5rem 1.625rem;
            gap: 1.625rem;
            cursor: pointer;
            border-radius: 10px;
            @media only screen and (max-width: $xl-screen) {
                margin-bottom: 0.5rem;
            }
            @media only screen and (max-width: $md-screen) {
                padding: 0.5rem 1rem;
                gap: 1rem;
            }
            &:hover,&:focus{
                background-color: rgba(255,255,255,0.16);
            }
            .menu-icon {
                width: fit-content;
            }
            .menu-item-text {
                color: $white;
                font-weight: $font-medium;
                font-size: 0.938rem;
                line-height: 1.125rem;
                text-align: left;
                display: inline-block;
                width: 100%;
                @media only screen and (max-width: $md-screen) {
                    font-size: 0.875rem;
                }
            }
            &.active {
                background-color: $white;
                box-shadow: inset -3px -3px 4px $white, inset 1px 1px 4px rgba(97, 97, 97, 0.5);
                border-radius: 10px;
                .menu-item-text {
                    color: $dark01;
                }
                svg {
                    * {
                        fill: $primary-color;
                    }
                }
            }
        }
    }
    @media only screen and (max-width: $xs-screen) {
        background-color: $secondary-color;
        position: fixed;
        z-index: 99;
        width: 100vw;
        left: 0;
        bottom: 0;
        border-radius: 0.5rem 0.5rem 0 0;
        box-shadow: 0px -1px 8px 0px rgba(0, 0, 0, 0.1);
        padding: 0.5rem 0.25rem;
        .logo-container,.range{
            display: none;
        }
        .list-group{
            flex-flow: row;
            justify-content: space-around;
            margin: 0;
            overflow: visible;
            .list-group-item{
                margin-bottom: 0;
                padding: 0.5rem;
                border-radius: 6px;
                .menu-item-text{
                    display: none;
                }
                .menu-icon{
                    svg *{
                        fill: $text-gray;
                    }
                }
                &.active{
                    background-color: $secondary-color;
                    box-shadow: -2px -2px 4px rgba(255, 255, 255, 0.95), 3px 3px 4px rgba(97, 97, 97, 0.12);
                    .menu-icon{
                        svg *{
                            fill: $primary-color;
                        }
                    }
                }
            }
        }
    }
    &.collapsed {
        width: $drawer-collapsed-width;
        min-width: $drawer-collapsed-width;
        transition: all 0.3s ease;
        height: initial;
        @media only screen and (max-width: $xs-screen) {
            width: 100%;
        }
        .logo-container {
            margin-bottom: 25px;
    
            .logo-image {
                text-align: center;
                .typography {
                    display: none;
                }
            }
            svg {
                cursor: pointer;
                position: absolute;
                top: -20px;
                right: -10px;
            }
        }
        .list-group {
            margin-top: 14px;
            .list-group-item {
                margin: 5px auto;
                padding: 0.5rem;
                .menu-item-text {
                    display: none;
                }
            }
        }
        .range {
            display: none;
        }
    }
    .sidebar-logout {
        cursor: pointer;
        color: #ffffff;
        font-weight: 500;
        font-size: 0.938rem;
        line-height: 1.125rem;
        text-align: left;
        display: inline-block;
        width: 100%;
        svg {
            path {
                fill: #ffffff;
            }
        }
    }
}
