@import "/src/Theme/custom.scss";

.form-check{
    &.form-switch{
        &.switch-lg{
            width: 4rem;
            height: 1.625rem;
            padding: 0;
            background: linear-gradient(314.71deg, rgba(0, 0, 0, 0.06) 8.64%, rgba(0, 0, 0, 6e-06) 89.83%), $gray-border;
            border: 1px solid $white;
            border-radius: 0.5rem;
            box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2), -6px -6px 12px rgba(255, 255, 255, 0.36), inset 3px 2px 6px rgba(0, 0, 0, 0.09), inset -2px -1px 6px rgba(255, 255, 255, 0.82);
            position: relative;
            overflow: hidden;
            input{
                opacity: 0;
                position: absolute;
                margin: 0;
                padding: 0;
                visibility: hidden;
                &~label{
                    position: relative;
                    cursor: pointer;
                    display: block;
                    padding-left: 2.188rem;
                    padding-right: 0.438rem;
                    font-size: 0.563rem;
                    font-weight: $font-bold;
                    color: $text-gray;
                    display: flex;
                    align-items: center;
                    height: 100%;
                    transition: 0.3s ease all;
                    &::before{
                        content: '';
                        background: linear-gradient(45deg, $secondary-color 0%, $form-disabled 100%);
                        box-shadow: -3px -3px 6px rgba(255, 255, 255, 0.207441), 1px 1px 2px rgba(0, 0, 0, 0.2), -6px -6px 12px rgba(255, 255, 255, 0.59711), inset 1px 1px 1px rgba(255, 255, 255, 0.241013);
                        width: 1.625rem;
                        height: 1.25rem;
                        border: 1px solid $white;
                        border-radius: 0.5rem;
                        position: absolute;
                        top: 50%;
                        transform: translate(0,-50%);
                        left: 0.188rem;
                        transition: 0.3s ease all;
                    }
                    &::after{
                        content: '';
                        background: $form-disabled;
                        background: -moz-linear-gradient(100deg, $white 0%, $light-gray 100%);
                        background: -webkit-linear-gradient(100deg, $white 0%, $light-gray 100%);
                        background: linear-gradient(100deg, $white 0%, $light-gray 100%);
                        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#f5f5f5",GradientType=1);
                        box-shadow: -3px -2px 12px rgba(0, 0, 0, 0.04), 2px 2px 4px rgba(255, 255, 255, 0.5), inset -2px -1px 4px rgba(0, 0, 0, 0.11), inset 3px 2px 4px rgba(255, 255, 255, 0.926491);
                        width: 0.25rem;
                        height: 0.75rem;
                        border-radius: 0.5rem;
                        position: absolute;
                        top: 50%;
                        transform: translate(0,-50%);
                        left: 0.9rem;
                        transition: 0.3s ease all;
                    }
                }
                &:checked{
                    &~label{
                        color: $primary-color;
                        padding-left: 0;
                        padding-right: 2.188rem;
                        padding-left: 0.438rem;
                        &::before{
                            left: 100%;
                            transform: translate(-1.813rem, -50%);
                            transition: 0.3s ease all;
                        }
                        &::after{
                            left: 100%;
                            transform: translate(-1.1rem, -50%);
                            transition: 0.3s ease all;
                        }
                    }
                }
            }
        }
    }
}