@import "/src/Theme/custom.scss";

.notification {
    // width: auto;
    .notification-body {
        width: 100%;
        background-color: $white;
        border-radius: 1rem;
        .container-fluid {
            padding: 2rem;
            @media only screen and (max-width: $md-screen) {
                padding: 1.5rem;
            }
            @media only screen and (max-width: $sm-screen) {
                padding: 1rem;
            }
        }
        .cards{
            .card{
                cursor: pointer;
                .card-body{
                    @media only screen and (max-width: $sm-screen) {
                        padding: 0.75rem;
                        .typography{
                            .text-18{
                                font-size: 1rem;
                                line-height: 1.25rem;
                            }
                            .text-14{
                                font-size: .75rem;
                            }
                        }
                    }
                }
            }
        }

        .item-details-loader {
            width: auto;

            .placeholder-glow {
                .placeholder-carousal-img {
                    height: 268px;
                    border-radius: 8px;
                }

                .img-button {
                    height: 35px;
                    width: 35px;
                }

                .item-title-loader {
                    height: 30px;
                }
            }
        }

        .notification-badge {
            display: flex;
            border-radius: 50%;
            height: 50px;
            width: 50px;
            align-items: center;
            justify-content: center;
            svg{
                height: 2.125rem;
                width: 2.125rem;
            }
            @media only screen and (max-width: $sm-screen) {
                width: 2.5rem;
                height: 2.5rem;
                svg{
                    height: 1.5rem;
                    width: 1.5rem;
                }
            }
        }
        .mark{
            background-color: #F1F1F1;
        }
    }
}