@import "/src/Theme/custom.scss";

.btn-primary-lg {
    width: 100%;
}

.check-otp {
    .otp-form {
        div {
            display: flex;
            width: 100%;
            justify-content: space-evenly;
        }
    }
    .otp-button {
        .Buttons {
            width: 100%;
        }
    }
}
