@import "/src/Theme/custom.scss";
.otp-form{
    input[type="text"]{
        background-color: $secondary-color;
        height: 3.5rem;
        width: 3.5rem;
        min-width: 3.5rem;
        border-radius: 0.625rem !important;
        font-size: 1.375rem;
        font-weight: $font-semibold;
        line-height: 1.5rem;
        color: $dark01;
        padding: 0.5rem;
        text-align: center;
        box-shadow: none;
        outline: none;
        margin: 0;
        z-index: 0;
        border: 1px solid rgba($color: $black, $alpha: 0.1);
        &::placeholder{
            font-size: 1.375rem;
            font-weight: $font-semibold;
            line-height: 1.5rem;
            color: $dark01;
        }
        &:focus{
            border-color: $info-color;
            z-index: 0;
        }
    }
}