@import "/src/Theme/custom.scss";

.spinners{
    display: flex;
    height: 97vh;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.button-spinner{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
}
.btn-primary-shadow{
    .button-spinner{
        .spinner-border{
            border-color: $white;
            border-right-color: transparent;
        }
    }
}