// @use "/src/Theme/custom.scss" as theme;
@import "/src/Theme/custom.scss";

.cards {
    width: auto;
    max-width: 100%;
    .login-card {
        background-color: $white;
        border-radius: 1.875rem;
        border: 2px solid $light-border;
        box-shadow: 0px 3px 20px rgba(136, 59, 60, 0.03);
        max-width: 720px;
        margin: 0 auto;
        @media only screen and (max-width: $xs-screen) {
            max-width: 400px;
        }
        .card-body {
            padding: 4.375rem;
            @media only screen and (max-width: $sm-screen) {
                padding: 3rem;
            }
            @media only screen and (max-width: $xs-screen) {
                padding: 2rem 1.5rem;
            }
        }
    }
    .single-product-card {
        background-color: $secondary-color;
        box-shadow: -2px -2px 4px rgba(255, 255, 255, 0.95), 3px 3px 4px rgba(97, 97, 97, 0.12);
        border-radius: 8px;
        border: none;
        .card-body{
            padding: 3px;
        }
    }
}

.cards{
    .card{
        border-radius: 0.5rem;
        &.order-card-gray{
            border: none !important;
            overflow: initial !important;
            .card-body{
                background-color: $secondary-color;
                border-radius: 0.5rem;
                border: 1px solid $light-border;
                box-shadow: -2px -2px 4px rgba(255, 255, 255, 0.95), 3px 3px 4px rgba(97, 97, 97, 0.12);
                padding: 0.75rem;
            }
        }
    }
}